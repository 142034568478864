<template>
    <div class="search-msg__img">
        <action :item="item" class="action"/>
        <Thumb :src="item.url"  width="100%" @click="handlerPreViewVideo(item)" />
        <div class="search-msg__img--info" @click="handlerToChat">
            <span class="title" v-html="item.value"></span>
            <span class="source" v-html="item.name"></span>
        </div>
    </div>
</template>
<script>
import Thumb from '@/components/common/thumb'
import action from './components/action'
import {parseTime} from '@/utils'
import { Base64 } from "js-base64";
export default {
    name:'search-msg-img',
    components:{Thumb,action},
    props:{
        item:{
            type:Object,
            default:()=>{
                return {}
            }
        }
    },
    created(){  
    },
    methods:{
        parseTime,
        handlerPreViewVideo(item){          
            window.open(item.url,item.name)
        },
        handlerToChat(){
            const chatObj = {
                id:this.item.id ,
                name: this.item.name,
                type:this.item.type,
                lastTime:this.item.timeStamp
            }
            localStorage.setItem('chatObj',JSON.stringify(chatObj))
            this.$router.push({
                name:'chatWith',
                params:{
                    id:"pai_" + Base64.encode(chatObj.id),
                    type:chatObj.type
                }
            })
        },
    }
}
</script>
<style lang="stylus">
@import '~styles/utils/index';
+b(search-msg__img){
    width:48%;
    max-height 190px
    margin 1%;
    display flex;
    flex-flow column
    position relative
    .action{
        position absolute
        right .25rem
        top .25rem
        z-index 2
        i{
            color rgba(255,255,255,.8)
            
        }
    }
    +m(info){
        flex 1
        display flex
        flex-flow column
        overflow hidden
        text-overflow:ellipsis;
        white-space nowrap
        padding .5rem;
        background #ffffff
        span{
            overflow hidden
            text-overflow:ellipsis;
            white-space nowrap
        }
        .title{
            font-size 14px;
            color #333333
        }
        .source{
            font-size 12px;
            color #999999
        }
    }
    .van-image{
        .van-image__error{
            background #dadada
            .error-img{
                background #dadada
                color #eaeaea
            }
        }
    }
}
</style>