var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-msg__img" },
    [
      _c("action", { staticClass: "action", attrs: { item: _vm.item } }),
      _c("i", {
        staticClass: "video",
        on: {
          click: function($event) {
            return _vm.handlerPreViewVideo(_vm.item)
          }
        }
      }),
      _c("div", { staticClass: "search-msg__img--video" }, [
        _c(
          "video",
          {
            attrs: {
              proload: "meta",
              width: "100%; height:160px;max-height;160px;",
              poster: _vm.item.thumb
            },
            on: {
              click: function($event) {
                return _vm.handlerPreViewVideo(_vm.item)
              }
            }
          },
          [_c("source", { attrs: { src: _vm.item.url } })]
        )
      ]),
      _c(
        "div",
        {
          staticClass: "search-msg__img--info",
          on: { click: _vm.handlerToChat }
        },
        [
          _c("span", {
            staticClass: "title",
            domProps: { innerHTML: _vm._s(_vm.item.value) }
          }),
          _c("span", {
            staticClass: "source",
            domProps: { innerHTML: _vm._s(_vm.item.name) }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }