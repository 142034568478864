var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "search-msg__file" }, [
    _vm.data.icon && _vm.data.icon.indexOf("file.") !== 0
      ? _c(
          "div",
          {
            staticClass: "search-msg__file--icon",
            on: {
              click: function($event) {
                return _vm.handlerPreView(_vm.data)
              }
            }
          },
          [_c("img", { attrs: { src: _vm.data.icon, alt: "" } })]
        )
      : _c(
          "div",
          {
            staticClass: "search-msg__file--icon",
            on: { click: _vm.handlerToChat }
          },
          [
            _c("span", {
              domProps: { innerHTML: _vm._s(_vm.data.fileSuffix.slice(0, 4)) }
            }),
            _c("i", { staticClass: "me-icon-file" })
          ]
        ),
    _c(
      "div",
      {
        staticClass: "search-msg__file--info",
        on: {
          click: function($event) {
            return _vm.handlerPreView(_vm.data)
          }
        }
      },
      [
        _c("div", { staticClass: "line" }, [
          _c("span", {
            staticClass: "title",
            domProps: { innerHTML: _vm._s(_vm.data.value) }
          }),
          _c("span", {
            staticClass: "search-msg__file--time",
            domProps: { innerHTML: _vm._s(_vm.parseTime(_vm.data.timeStamp)) }
          })
        ]),
        _c(
          "div",
          { staticClass: "line" },
          [
            _c("span", {
              staticClass: "source",
              domProps: { innerHTML: _vm._s(_vm.data.name) }
            }),
            _c("action", { attrs: { item: _vm.data } })
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }