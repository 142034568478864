<template>
    <div class="search-msg__img">
        <!-- <Thumb :src="item.thumb"  width="100%" /> -->
        <action :item="item" class="action" />
        <i class="video" @click="handlerPreViewVideo(item)"></i>
        <div class="search-msg__img--video">
        <video proload="meta" width="100%; height:160px;max-height;160px;" :poster="item.thumb" @click="handlerPreViewVideo(item)">
            <source :src="item.url">
        </video>
        </div>
        <div class="search-msg__img--info" @click="handlerToChat">
            <span class="title" v-html="item.value"></span>
            <span class="source" v-html="item.name"></span>
        </div>
    </div>
</template>
<script>
import {parseTime} from '@/utils'
import { Base64 } from "js-base64";
import action from './components/action'
export default {
    name:'search-msg-img',
    components:{action},
    props:{
        item:{
            type:Object,
            default:()=>{
                return {}
            }
        }
    },
    created(){
        console.log(this.item,'/img item');
        
    },
    methods:{
        parseTime,
        handlerPreViewVideo(item){            
            window.open(item.url,item.name)
            // Location.href = 
        },
          handlerToChat(){
            const chatObj = {
                id:this.item.id ,
                name: this.item.name,
                type:this.item.type,
                lastTime:this.item.timeStamp
            }
            localStorage.setItem('chatObj',JSON.stringify(chatObj))
            this.$router.push({
                name:'chatWith',
                params:{
                    id:"pai_" + Base64.encode(chatObj.id),
                    type:chatObj.type
                }
            })
        },
    }
}
</script>
<style lang="stylus">
@import '~styles/utils/index';
+b(search-msg__img){
    width:48%;
    margin 1%;
    max-height 190px
    display flex;
    flex-flow column
    position relative
    .action{
        position absolute
        right .25rem
        top .25rem
        z-index 2
        i{
            color rgba(255,255,255,.8)
            
        }
    }
    +m(video){
        height 140px;
        max-height 140px;
        overflow hidden
        video{
            background #dadada
            height 140px
            object-fit cover
        }
    }
 
    .video{
        position absolute
        left 40%
        top 20%
        &::before{
            font-family 'iconfont'
            content '\e641'
            font-size 40px;
            color rgba(255,255,255,0.9)
        }    
    }
    
    +m(info){
        flex 1
        display flex
        flex-flow column
        overflow hidden
        text-overflow:ellipsis;
        white-space nowrap
        padding .5rem;
        background #ffffff
        span{
            overflow hidden
            text-overflow:ellipsis;
            white-space nowrap
        }
        .title{
            font-size 14px;
            color #333333
        }
        .source{
            font-size 12px;
            color #999999
        }
    }
}
</style>