<template>
    <div class="search-msg">
        <Header class="search-msg__default">
            <i class="me-icon-back" slot="left-one" @click="$router.go(-1)"></i>
            <van-search slot="center"
            class="search-msg__search"
                v-model="keyword"            
                placeholder=""
                autofocus
                left-icon=""
                @search="onSearch()"
            >
            <i slot="right-icon" class="me-icon-search" @click="keyword ? onSearch() : ''"></i>
            </van-search>
            <i :class="{'me-icon-owner':true,'active':groupOwnerId}" @click="handerChangePupUp" slot="right-one" />
        </Header>
         <van-popup
             v-model="show"
            position="right"
            custom-style="height: 100%;"
            bind:close="onClose"
            class="search-msg__pupUp"
            v-scroll="{
                distance:150,
                onTop:_onTopOwner,
                onBottom:_onBottomOwner
            }"
            >
            <!-- <i class="me-icon-close"></i> -->
            <van-cell-group>
                <van-cell v-for="(item, index) in groupOwners" :key="index" @click="handlerChoseOwner(item.groupOwnerId)">
                    <div class="search-msg__groupOwner" slot="title">
                        <Avatar :src="item.avatar" width="30px" v-if="item.groupOwnerId" height="30px"/>
                        <span class="name" v-html="item.groupOwnerName"></span>
                        <van-icon name="checked"  v-if="item.groupOwnerId == groupOwnerId" />
                    </div>
                </van-cell>
            </van-cell-group>

        </van-popup>
        <div class="search-msg__type">
            <i v-for="(item, index) in types" :key="index" @click="active = index" :class="[{'active':index === active},`me-icon-${item}`]" />
        </div>
       
        <div :class="{'search-msg__list':true,'search-msg__rowBox':active === 1 || active === 2}" v-scroll="{
            distance: 150,
            onTop: _onTop,
            onBottom: _onBottom
            }">
             <div class="search-msg__file--type" v-if="active ==3">
                <span v-for="(item, index) in suffixTypes" @click="_changesuffix(item,index)" :key="index" :class="{'active':index === typeActive}">{{item}}</span>
            </div>
            <div class="search-msg__empty" v-if="!list.length">
                <i class="me-icon-empty"></i>
            </div>
            <template  v-else v-for="(item, index) in list">
                <component :is="`message${curType}`"  :key="index" :item="item"></component>
            </template>
            
        </div>       
    </div>
</template>
<script>
import Header from "@/components/business/h5/header";
import {searchByKeyword,searchChatDataAction,getGroupOwners,searchFiles} from '@/api/im'
// const chatMsg = require('@/assets/images/search/chatmsg.png')
// const imgIcon = require('@/assets/images/search/image.png')
// const videoIcon = require('@/assets/images/search/video.png')
// const fileLink = require('@/assets/images/search/files.png')
// const owner = require('@/assets/images/search/owner.png')
import Avatar from "@/components/common/avatar";
const msgType = ['chat_message','friend_name','group_name']
import messagechat from '@/components/business/imchat/search/message-chat'
import messageimage from '@/components/business/imchat/search/message-img'
import messagevideo from '@/components/business/imchat/search/message-video'
import messagefile from '@/components/business/imchat/search/message-file'
import {deepClone} from '@/utils'
export default {
    name:'search-msg',
    data(){
        return{
            keyword:'',
            language:'',
            user:{},
            page:0,
            pageSize:30,
            types:[
                'chatMsg',
                'imgIcon',
                'videoIcon',
                'fileLink'
            ],
            // owner,
            active:0,
            curType:'chat',
            list:[],
            groupOwnerId:'',
            show:false,
            groupOwners:[],
            ownerPage:1,
            suffix:'',
            suffixTypes:['zip','txt','pdf','docx','xls','ppt','...'],
            typeActive:'',
            serachFirst:false,
            suffixType: 1
        }
    },
    components:{
        Header,
        messagechat,
        messageimage,
        messagevideo,
        messagefile,
        Avatar
    },
    watch:{
        active:{
            handler(newly){
                this.page = 0
                this.list = []
                this.suffix = ''
                this.serachFirst = false
                switch (newly) {
                    case 0:
                        this.curType = 'chat'
                        this.onSearch()                        
                        break;
                    case 1:
                        this.curType = 'image'
                        this.handlerImgSearchChatDataAction()
                        break
                    case 2:
                        this.curType = 'video'
                        this.handlerImgSearchChatDataAction()
                        break
                    case 3:
                        this.curType = 'file'
                        this.handlerImgSearchChatDataAction()
                        break
                }
                
            }
        },
        suffix:{
            handler(){
                this.page = 0
                this.list = []
                this.onSearch()
                
            },
            deep:true
        },
        groupOwnerId:{
            handler(val){
                this.onSearch()
                console.log(val,'/val');
                
            }
        },
        list:{
            handler(val){
                console.log(val,'/val');
                this.$forceUpdate()
            },deep:true
        }
    },
    created(){
        this.language = localStorage.getItem('langCode') || '1'
        this.user = JSON.parse(localStorage.getItem('userinfo') || {})
        this.onSearch()
    },
    methods:{
        handlerSwitchSuffix(){
            let suffixArr = ''
            this.suffixType = 1
            switch (this.suffix) {
                case "docx":
            suffixArr = "doc,docx";
            break;
          case "xls":
            suffixArr = "xls,xlsx";
            break;
          case "ppt":
            suffixArr = "ppt,pptx";
            break;
          case "zip":
            suffixArr = "zip,rar";
            break;
          case "...":
            suffixArr = deepClone(this.suffixTypes)
              .concat(["doc", "xlsx", "pptx", "rar"])
              .map(el => {
                if (el !== "...") return el;
              })
              .join(",");
            this.suffixType = 0;
            break;
        default:
            suffixArr = this.suffix
            break;
            }
            return suffixArr
        },
        _onTopOwner(){},
        _onBottomOwner(){
            this.ownerPage ++
            this.handlerGetGroupOwners(this.ownerPage)
        },
        _changesuffix(val,index){
            this.suffix = val
            if(this.typeActive === index) {
                this.typeActive = ''
                this.suffix = ''
            }
            else this.typeActive = index
        },
        async handlerGetGroupOwners(page = 1,init){
            const user = JSON.parse(localStorage.getItem('userinfo') || {})
            const params = {
                userId: user.id,
                page,
                pageSize:this.pageSize,
                language:this.language
            }
            const result = await getGroupOwners(params)
            if(result && result.code === 200){
                const {data} = result.data
                const all = {
                    groupOwnerName:this.$t('all'),
                    groupOwnerId:'',
                    avatar:''
                }
                if(init) this.groupOwners = data
                else this.groupOwners =  this.groupOwners.concat(data)
                if(!this.groupOwners.some(item=>item.groupOwnerId === '')) this.groupOwners.unshift(all)
            }
            
            
        },
        handlerChoseOwner(id){
            this.groupOwnerId = id
            this.show = !this.show
        },
        _onTop(){},
        _onBottom(){
            this.page ++
            if(!this.serachFirst){
                // this.list = []
                this.serachFirst = true
                // this.page = 0
            }
            this.onSearch(this.page)
        },
        onClose(e){
            
        },
        async handerChangePupUp(){
            if(!this.groupOwners.length)
                await this.handlerGetGroupOwners(1,true)
            this.show = !this.show
            
        },
       async onSearch(page = 0){           
            switch (this.active) {
                    case 0:
                        this.handlerSearchChatMsg(page)                        
                        break;
                    case 1:
                        this.handlerImgSearchChatDataAction(page)
                        break
                    case 2:
                        this.handlerImgSearchChatDataAction(page)
                        break
                    case 3:
                        this.handlerImgSearchChatDataAction(page)
                        break
                }
        },
        async handlerSearchChatMsg(page = 0){
            const params = {
                keyWord:this.keyword,
                userId:this.user.id,
                page:page,
                pageSize:this.pageSize,
                groupOwner:this.groupOwnerId
            }
            const result = await searchByKeyword(params)
            const {data} = result
            if(result && result.code === 200){
               const list = data.data.filter(item=>{
                    if(msgType.includes(item.elasticsearchType)) return item
                })
                this.list = !page ? list : this.list.concat(list)
            }
        },
        async handlerImgSearchChatDataAction(page = 0){
            const params = {
                keyWord:this.keyword,
                page:page,
                pageSize:this.pageSize,
                userId:this.user.id,
                groupOwner:this.groupOwnerId,
                suffix:this.handlerSwitchSuffix(),
                suffixType:this.suffixType
            }
            const result = await searchFiles(this.curType,params)
            console.log(result,'result file');
            
            if(result && result.code == 200){
                const {data} = result.data
                const list = data.map(item=>{
                    const {chatTitle, groupName,friendName,from,timeStamp,url,value,thumb,fileSuffix} = item
                    return{
                        name:chatTitle || groupName || friendName,
                        value,
                        timeStamp,
                        url,
                        id:from.replace('G',''),
                        type:from.indexOf('G') === 0 ? 'group' : 'chat',
                        thumb,
                        fileSuffix
                    }
                })
                this.list = !page ? list : this.list.concat(list)
                console.log(list,'/list');                
            }            
            
        }
    }
}
</script>
<style lang="stylus">
@import '~styles/utils/index';
    i{
        &.active{
            color #33cc66
        }
    }
+b(search-msg){
    +e(file){
        +m(type){
            display flex;
            flex-flow row
            padding .9rem 0.5rem
            justify-content space-around
            flex-wrap wrap
            background #ffffff
            span{
                padding .2rem .8rem
                border 1px solid #e5e5e5                
                border-radius 5rem
                color #333333
                font-size 14px
                &.active{
                    border 1px solid #33cc66
                    color #33cc66                    
                    &::after{
                        font-family 'iconfont'
                        content '\e68b'
                        // position absolute
                    }
                }
                // margin 0 .3rem .3rem 0

            }
        }
    }
    +e(empty){
        height 60vh
        width 100%
        display flex;
        align-items center
        justify-content center
        i{
            font-size 50px
            color #999999
        }
    }
    .header{
        .left{
            margin-left .5rem
        }
        i{
            margin 0 .25rem;
        }
    }
    +e(pupUp){
        width:80%;
        height 100vh
        overflow-y scroll
        padding 1.9rem .5rem
        .van-icon-checked{
            // position absolute
            // left - 30px;
            background #ffffff;
            color #33cc66
            font-size 20px
        }
        .van-cell-group{
            .van-cell{
                // padding 10px 0
                +e(title){
                    max-width 100%
                }
            }
        }
        // top:5%
    }
    +e(groupOwner){
        display  flex;
        flex-flow row
        align-items center
        overflow hidden
        text-overflow:ellipsis
        white-space nowrap
        
        .name{
            flex 1
            overflow hidden
            text-overflow:ellipsis
            white-space nowrap
        }
        .me-icon-check{
            font-size 18px;
            color #33cc66
        }
    }
    +e(default){
        background rgb(246,246,246);
        color #666666
        .center{
            margin 0 .5rem;
        }
    }
    +e(rowBox){
        display flex;
        flex-flow row
        flex-wrap wrap
        // background #ffffff
    }
    
    +e(search){
        padding:0;
        background #ececec !important;
        +m(owner){
            width:30px;
            margin-right .5rem;
        }
    }
    .van-search{
        +e(content){
            background #ffffff
            .van-field{
                +e(right-icon){
                    .van-icon-search{
                        font-size 24px
                    }
                }    
            }
        }
    }
    +e(list){
        margin-top 62px;
        padding-top:62px;
        max-height 84vh;
        overflow-y scroll
    }
    +e(type){
        display flex;
        flex-flow row;
        align-items center;
        justify-content space-around;
        // margin-top 62px;
        padding .9rem 0
        background #f6f6f6
        position fixed;
        z-index 3
        width 100%

        // padding;
        i{
            // margin 0 1.9rem;
            font-size 30px;
            // padding .9rem;
            // background #ffffff;
            // border 1px solid #e5e5e5;
            // border-radius 10rem;
            color #6b6b6b
            &.active{
                color #33cc66
            }
        }
    }
}
</style>