<template>
    <div class="search-msg__file">
        <div v-if="data.icon && data.icon.indexOf('file.') !== 0"  class="search-msg__file--icon"  @click="handlerPreView(data)">
            <img :src="data.icon" alt="">
            <!-- @click="handlerToChat" -->
        </div>
        <div class="search-msg__file--icon" v-else @click="handlerToChat">
            <span  v-html="data.fileSuffix.slice(0,4)"></span>
            <i class="me-icon-file"></i>
        </div>
        <div class="search-msg__file--info"  @click="handlerPreView(data)">
            <div class="line">
             <span class="title" v-html="data.value"></span>
             <span class="search-msg__file--time" v-html="parseTime(data.timeStamp)"></span>
            </div>
            <div class="line">
                <span class="source" v-html="data.name"></span>
             <action :item="data" />
            </div>
        </div>
        
    </div>
</template>
<script>
import {parseTime} from '@/utils'
import {formartfileIcon} from '@/utils/help'
import action from './components/action'
import { Base64 } from "js-base64";
export default {
    name:'search-msg-img',
    components:{action},
    props:{
        item:{
            type:Object,
            default:()=>{
                return {}
            }
        }
    },
    data(){
        return{
            data:{}
        }
    },
    watch:{
        item:{
            handler(val){
                this.handlerforMartItem()                
                this.$forceUpdate()
            },deep:true
        }
    },
    created(){
        this.handlerforMartItem()        
    },
    methods:{
        handlerforMartItem(){
            const fileType = this.item.value.substr(this.item.value.lastIndexOf('.') + 1,3)
        this.data = {
            ...this.item,
            lst:fileType,
            icon:formartfileIcon(fileType)
        }
        },
        parseTime,
        handlerPreView(item){            
            window.open(item.url,item.name)
        },
         handlerToChat(){
            const chatObj = {
                id:this.item.id ,
                name: this.item.name,
                type:this.item.type,
                lastTime:this.item.timeStamp
            }
            localStorage.setItem('chatObj',JSON.stringify(chatObj))
            this.$router.push({
                name:'chatWith',
                params:{
                    id:"pai_" + Base64.encode(chatObj.id),
                    type:chatObj.type
                }
            })
        },
    }
}
</script>
<style lang="stylus">
@import '~styles/utils/index';
+b(search-msg__file){
    display flex;
    flex-flow row
    position relative
    background #ffffff
    padding .5rem
    border-bottom 1px solid #e5e5e5
    +m(icon){
        min-width 40px;
        position relative
        display flex
        align-items center
        justify-content center
        img{
            width 40px
            height 40px
            object-fit cover
        }
        i{
            font-size 12px
            color #1a92ed
            &::before{
                 font-size 46px
            }
        }
        span{
            position absolute
            left 10px
            top 22px
            font-size 12px
            color #1a92ed
            // font-weight bold
        }
    }
    +m(time){
        font-size 12px;
        color #999999
        min-width 112px
        // padding .5rem 0;
    }
    
    +m(info){
        flex 1
        // display flex
        // flex-flow column
        overflow hidden
        text-overflow:ellipsis;
        white-space nowrap
        padding .5rem;
       .line{
           display flex
           flex-flow row;
       }
        span{
            overflow hidden
            text-overflow:ellipsis;
            white-space nowrap
        }
        .title{
            font-size 14px;
            color #333333
            flex 1
        }
        .source{
            font-size 12px;
            color #999999
            overflow hidden
            text-overflow:ellipsis;
            white-space nowrap
            flex 1
        }
    }
}
</style>