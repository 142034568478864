var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "van-cell",
    {
      staticClass: "chatmsg-item",
      attrs: {
        "title-class": !["friend_name", "group_name"].includes(
          _vm.item.elasticsearchType
        )
          ? "chatmsg-item__column"
          : "chatmsg-item__row"
      },
      on: { click: _vm.handlerToChat }
    },
    [
      _vm.item.elasticsearchType == "chat_message"
        ? _c("template", { slot: "title" }, [
            _c("div", { staticClass: "chatmsg-item--title__line" }, [
              _c("span", {
                staticClass: "chatmsg-item--title__text",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.item.chatTitle ||
                      _vm.item.groupName ||
                      _vm.item.friendName
                  )
                }
              }),
              _c("span", {
                staticClass: "chatmsg-item--title__time",
                domProps: {
                  innerHTML: _vm._s(_vm.parseTime(_vm.item.timeStamp))
                }
              })
            ]),
            _c("span", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.item.lastMessageUserName +
                    "：" +
                    _vm.item.value.replace(/(?!<font.+?>)<.+?><\/?.+?\/?>/g, "")
                )
              }
            })
          ])
        : _vm._e(),
      _vm.item.elasticsearchType == "group_name"
        ? _c("template", { slot: "title" }, [
            _c("span", { domProps: { innerHTML: _vm._s(_vm.item.groupName) } })
          ])
        : _vm._e(),
      _vm.item.elasticsearchType == "friend_name"
        ? _c(
            "template",
            { slot: "title" },
            [
              _c("Avatar", {
                attrs: {
                  src: _vm.formartImageUrl(_vm.item.friendPic),
                  round: true,
                  width: "40px",
                  height: "40px"
                }
              }),
              _c("span", {
                domProps: { innerHTML: _vm._s(_vm.item.friendName) }
              }),
              _vm.item.friendTags
                ? _vm._l(
                    _vm.handlerFormartTag(
                      _vm.item.friendTags,
                      _vm.item.friendName
                    ),
                    function(tag, _key) {
                      return _c("van-tag", {
                        key: _key,
                        domProps: { innerHTML: _vm._s(tag) }
                      })
                    }
                  )
                : _vm._e()
            ],
            2
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }