<template>
    <van-cell class="chatmsg-item" :title-class="!['friend_name','group_name'].includes(item.elasticsearchType)  ? 'chatmsg-item__column' : 'chatmsg-item__row'"
    @click="handlerToChat">
        <template slot="title" v-if="item.elasticsearchType == 'chat_message'">
           <div class="chatmsg-item--title__line">
                <span class="chatmsg-item--title__text" v-html="item.chatTitle || item.groupName || item.friendName"></span>
            <span class="chatmsg-item--title__time" v-html="parseTime(item.timeStamp)"></span>
           </div>
           <span v-html="item.lastMessageUserName + '：' + item.value.replace(/(?!<font.+?>)<.+?><\/?.+?\/?>/g,'')"></span>
        </template>               
        <template slot="title" v-if="item.elasticsearchType == 'group_name'" >
            <span v-html="item.groupName"></span>
        </template>
        <template slot="title" v-if="item.elasticsearchType == 'friend_name'" >
             <Avatar :src="formartImageUrl(item.friendPic)" :round="true" width="40px" height="40px" />
            <span v-html="item.friendName"></span>
            <template  v-if="item.friendTags">
                <van-tag v-for="(tag, _key) in handlerFormartTag(item.friendTags,item.friendName)" :key="_key" v-html="tag"></van-tag>
            </template>
        </template>
    </van-cell>
</template>
<script>
import {parseTime} from '@/utils'
import {formartImageUrl} from '@/utils/help'
import { Base64 } from "js-base64";
import Avatar from "@/components/common/avatar";
export default {
    name:'chatmsg',
    components:{
        Avatar
    },
    props:{
        item:{
            type:Object,
            default:()=>{ return {} }
        }
    },
    created(){         
        
    },
    methods:{
        parseTime,
        formartImageUrl,
        handlerToChat(){
            const chatObj = {
                id:this.item.friendId || this.item.groupId || this.item.from.replace('G',''),
                name: this.item.friendName || this.item.groupName,
                type:this.item.friendId ? 'chat': 'group',
                lastTime:this.item.elasticsearchType === 'chat_message' ?this.item.timeStamp : ''
            }
            localStorage.setItem('chatObj',JSON.stringify(chatObj))
            this.$router.push({
                name:'chatWith',
                params:{
                    id:"pai_" + Base64.encode(chatObj.id),
                    type:chatObj.type
                }
            })
        },
        handlerFormartTag(tag,name){
            let tags = []
            if(tag){
                if(tag.split(',').length)
                tags = tag.split(',').length
                else tags = [tag]
            }
            console.log(tag,tags,name,'////');
            
            return tags
        }
    }
}
</script>
<style lang="stylus">
@import '~styles/utils/index';
+b(chatmsg-item){
    +m(title){
        +e(line){
            display flex;
            flex-flow row;
            justify-content space-between
            align-items flex-start
        }
        +e(text){
            font-size 14px
            // font-weight bold
            color #333333 !important
            white-space nowrap
            margin 0 0 .25rem 0
        }
        +e(time){
            color #999999
            font-size 12px
            min-width 112px;
        }
    }
   &.van-cell{
       font-size 13px
       color #505050
       +e(title){
           display flex !important;
           color #333333 !important
       }
   }
   +e(row){
        display flex
        flex-flow row
        align-items center
        .van-image{
            margin-right .5rem;
        }
        overflow hidden
        text-overflow:ellipsis
        // white-space nowrap
        max-height 44px
        span{
            overflow hidden
            text-overflow:ellipsis
            // white-space nowrap
            font-size 14px;
            // font-weight bold
            color #999999
            max-height 44px
            
        }
   }
   +e(column){
        display flex
        flex-flow column
        overflow hidden
        text-overflow:ellipsis
        white-space nowrap
        max-height 44px
        span{
            overflow hidden
            text-overflow:ellipsis
            white-space nowrap
            max-height 44px
            line-height 18px
            color #999999
            
        }
   }

}
</style>