var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-msg" },
    [
      _c(
        "Header",
        { staticClass: "search-msg__default" },
        [
          _c("i", {
            staticClass: "me-icon-back",
            attrs: { slot: "left-one" },
            on: {
              click: function($event) {
                return _vm.$router.go(-1)
              }
            },
            slot: "left-one"
          }),
          _c(
            "van-search",
            {
              staticClass: "search-msg__search",
              attrs: {
                slot: "center",
                placeholder: "",
                autofocus: "",
                "left-icon": ""
              },
              on: {
                search: function($event) {
                  return _vm.onSearch()
                }
              },
              slot: "center",
              model: {
                value: _vm.keyword,
                callback: function($$v) {
                  _vm.keyword = $$v
                },
                expression: "keyword"
              }
            },
            [
              _c("i", {
                staticClass: "me-icon-search",
                attrs: { slot: "right-icon" },
                on: {
                  click: function($event) {
                    _vm.keyword ? _vm.onSearch() : ""
                  }
                },
                slot: "right-icon"
              })
            ]
          ),
          _c("i", {
            class: { "me-icon-owner": true, active: _vm.groupOwnerId },
            attrs: { slot: "right-one" },
            on: { click: _vm.handerChangePupUp },
            slot: "right-one"
          })
        ],
        1
      ),
      _c(
        "van-popup",
        {
          directives: [
            {
              name: "scroll",
              rawName: "v-scroll",
              value: {
                distance: 150,
                onTop: _vm._onTopOwner,
                onBottom: _vm._onBottomOwner
              },
              expression:
                "{\n            distance:150,\n            onTop:_onTopOwner,\n            onBottom:_onBottomOwner\n        }"
            }
          ],
          staticClass: "search-msg__pupUp",
          attrs: {
            position: "right",
            "custom-style": "height: 100%;",
            "bind:close": "onClose"
          },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "van-cell-group",
            _vm._l(_vm.groupOwners, function(item, index) {
              return _c(
                "van-cell",
                {
                  key: index,
                  on: {
                    click: function($event) {
                      return _vm.handlerChoseOwner(item.groupOwnerId)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "search-msg__groupOwner",
                      attrs: { slot: "title" },
                      slot: "title"
                    },
                    [
                      item.groupOwnerId
                        ? _c("Avatar", {
                            attrs: {
                              src: item.avatar,
                              width: "30px",
                              height: "30px"
                            }
                          })
                        : _vm._e(),
                      _c("span", {
                        staticClass: "name",
                        domProps: { innerHTML: _vm._s(item.groupOwnerName) }
                      }),
                      item.groupOwnerId == _vm.groupOwnerId
                        ? _c("van-icon", { attrs: { name: "checked" } })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search-msg__type" },
        _vm._l(_vm.types, function(item, index) {
          return _c("i", {
            key: index,
            class: [{ active: index === _vm.active }, "me-icon-" + item],
            on: {
              click: function($event) {
                _vm.active = index
              }
            }
          })
        }),
        0
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "scroll",
              rawName: "v-scroll",
              value: {
                distance: 150,
                onTop: _vm._onTop,
                onBottom: _vm._onBottom
              },
              expression:
                "{\n        distance: 150,\n        onTop: _onTop,\n        onBottom: _onBottom\n        }"
            }
          ],
          class: {
            "search-msg__list": true,
            "search-msg__rowBox": _vm.active === 1 || _vm.active === 2
          }
        },
        [
          _vm.active == 3
            ? _c(
                "div",
                { staticClass: "search-msg__file--type" },
                _vm._l(_vm.suffixTypes, function(item, index) {
                  return _c(
                    "span",
                    {
                      key: index,
                      class: { active: index === _vm.typeActive },
                      on: {
                        click: function($event) {
                          return _vm._changesuffix(item, index)
                        }
                      }
                    },
                    [_vm._v(_vm._s(item))]
                  )
                }),
                0
              )
            : _vm._e(),
          !_vm.list.length
            ? _c("div", { staticClass: "search-msg__empty" }, [
                _c("i", { staticClass: "me-icon-empty" })
              ])
            : _vm._l(_vm.list, function(item, index) {
                return [
                  _c("message" + _vm.curType, {
                    key: index,
                    tag: "component",
                    attrs: { item: item }
                  })
                ]
              })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }