var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "serach-msg__action" },
    [
      _c("i", {
        staticClass: "me-icon-moreDian",
        on: { click: _vm.handlerChangeShow }
      }),
      _c(
        "van-popup",
        {
          attrs: { "get-container": "body", "overlay-style": _vm.style },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c("ul", { staticClass: "serach-msg__action-list" }, [
            _c("li", { on: { click: _vm.handlerToChat } }, [
              _c("i", { staticClass: "me-icon-chat-dot" })
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }