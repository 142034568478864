<template>
    <div class="serach-msg__action">
        <i class="me-icon-moreDian" @click="handlerChangeShow"></i>
        <van-popup v-model="show" get-container="body" :overlay-style="style">
            <ul class="serach-msg__action-list">
                <li @click="handlerToChat">
                    <i class="me-icon-chat-dot"></i>
                </li>
            </ul>
        </van-popup>
    </div>
</template>
<script>
import { Base64 } from "js-base64";
export default {
    name:'serach-msg-action',
    props:{
        item:{
            type:Object,
            default:()=>{
                return {}
            }
        }
    },
    data(){
        return{
            show:false,
            style:{                
                background: 'rgba(255,255,255,.5)'        
            }
        }
    },
    methods:{
        handlerChangeShow(event){
            event.stopPropagation();
            this.show = !this.show
        },
        handlerToChat(){
            const chatObj = {
                id:this.item.id ,
                name: this.item.name,
                type:this.item.type,
                lastTime:this.item.timeStamp
            }
            localStorage.setItem('chatObj',JSON.stringify(chatObj))
            this.$router.push({
                name:'chatWith',
                params:{
                    id:"pai_" + Base64.encode(chatObj.id),
                    type:chatObj.type
                }
            })
        },
    }
}
</script>
<style lang="stylus">
    .serach-msg__action{
        i{
            // font-size 15px
            color #999999
            font-size 20px
        }
    }
    .serach-msg__action-list{
        padding .5rem;
        background #333333;
        border-radius .25rem;
        color #ffffff
        li{
            i{
                font-size 24px
            }
        }
    }
</style>