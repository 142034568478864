var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-msg__img" },
    [
      _c("action", { staticClass: "action", attrs: { item: _vm.item } }),
      _c("Thumb", {
        attrs: { src: _vm.item.url, width: "100%" },
        on: {
          click: function($event) {
            return _vm.handlerPreViewVideo(_vm.item)
          }
        }
      }),
      _c(
        "div",
        {
          staticClass: "search-msg__img--info",
          on: { click: _vm.handlerToChat }
        },
        [
          _c("span", {
            staticClass: "title",
            domProps: { innerHTML: _vm._s(_vm.item.value) }
          }),
          _c("span", {
            staticClass: "source",
            domProps: { innerHTML: _vm._s(_vm.item.name) }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }